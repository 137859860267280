import FontFaceObserver from "fontfaceobserver";

setTimeout(() => {
    const materialIcons = new FontFaceObserver('Material Icons');

    materialIcons.load().then(() => {
        document.getElementsByTagName('html')[0].classList.add('material-icons-active');
    }, () => {
        console.log('Material Icons is not available');
    });
});
